.footer-favorites {
    display: none;
    background: #f5f5f5;
    padding: 40px 0;

    h3 {
        font-size: 24px;
        font-weight: 600;
        color: #555;
        margin-bottom: 40px;

        span {
            font-size: 18px;
            font-weight: 300;
            color: #777;
            margin-left: 15px;
        }
    }

    .footer-favorites-action {
        li {
            &:first-of-type {
                margin-right: 3rem;
            }

            a {
                font-size: 15px;
                font-weight: 300;
                color: #555;

                &:hover {
                    text-decoration: none;
                    color: color('primary');
                }

                i {
                    font-size: 18px;
                    color: color('primary');
                    margin-left: 10px;
                    position: relative;
                    top: 2px;
                }
            }
        }
    }

}